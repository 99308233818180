import Cookies from 'js-cookie';

import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'

window.Alpine = Alpine;
Alpine.plugin(collapse);

document.addEventListener('alpine:init', () => {
  // searchDrawer controller.
  Alpine.data('searchDrawer', () => ({
    taskId: Cookies.get('taskId') || 0,
    responseShown: Cookies.get('responseShown') || 0,
    select(taskId) {
      this.taskId = taskId;
      Cookies.set('taskId', taskId, { sameSite: 'strict', expires: 1 });
      this.responseShown = 0;
      Cookies.set('responseShown', 0, { sameSite: 'strict', expires: 1/48 }); // 30 minutes.
    },
    showResponse(taskId) {
      this.responseShown = taskId;
      Cookies.set('responseShown', taskId, { sameSite: 'strict', expires: 1/48 }); // 30 minutes.
    },
    isActive(taskId) {
      return taskId == this.taskId;
    },
    isResponseShown(taskId) {
      return taskId == this.responseShown;
    }
  }))
});

// Assign variables to the global scope so that they are accessible to Alpine.
window.Cookies = Cookies;

Alpine.start();

// Import just the needed components from Bootstrap's JS.

// Bootstrap components requiring JavaScript:
// - Alerts for dismissing
// - Buttons for toggling states and checkbox/radio functionality
// - Carousel for all slide behaviors, controls, and indicators
// - Collapse for toggling visibility of content
// - Dropdowns for displaying and positioning (also requires Popper)
// - Modals for displaying, positioning, and scroll behavior
// - Navbar for extending our Collapse and Offcanvas plugins to implement responsive behaviors
// - Navs with the Tab plugin for toggling content panes
// - Offcanvases for displaying, positioning, and scroll behavior
// - Scrollspy for scroll behavior and navigation updates
// - Toasts for displaying and dismissing
// - Tooltips and popovers for displaying and positioning (also requires Popper)
//
// Some plugins and CSS components depend on other plugins. If you include
// plugins individually, make sure to check for these dependencies in the docs.
// Our dropdowns, popovers, and tooltips also depend on Popper.
//
// Ref: https://getbootstrap.com/docs/5.2/getting-started/introduction/#js-components
// Ref: https://getbootstrap.com/docs/5.2/getting-started/javascript/

import Button from 'bootstrap/js/dist/button';
import Collapse from 'bootstrap/js/dist/collapse';
import Dropdown from 'bootstrap/js/dist/dropdown';
import Modal from 'bootstrap/js/dist/modal';

window.isInViewport = function (element) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
